<template>
  <div  style="background-color: #000;display: flex;flex-direction: column;align-items: center;
  font-size: 10px;position: absolute;bottom: 0;padding: 0.7rem 1rem;left: 0;right: 0;z-index: 1200">
    <div style="margin-bottom: 6px">
<!--      <a style="text-decoration:underline;cursor: pointer;margin-right: 12px" @click="openLink('/about')">关于只只</a>-->
      <a style="text-decoration:underline;cursor: pointer" @click="openLink('/contact')">关于我们</a>
    </div>
     <span style="margin-bottom: 8px">浙ICP备2024100753号-1 ｜联系邮箱：zhizhibook@foxmail.com</span>
     <span style="margin-bottom: 8px">
       <img src="../asset/policeIcon.png" style="width: 12px">
       浙公网安备31011202424533
     </span>
    <div >
<!--      <a style="text-decoration:underline;cursor: pointer" @click="openLink('/doc?docType=user')">用户协议</a>-->
<!--      <a style="text-decoration:underline;cursor: pointer;margin: 0 8px" @click="openLink('/doc?docType=authority')">权限列表用途</a>-->
<!--      <a style="text-decoration:underline;cursor: pointer" @click="openLink('/doc?docType=privacy')">隐私协议</a>&nbsp;&nbsp;&nbsp;-->
<!--      <a style="">版本信息：1.0.2</a>-->
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {

    };
  },
  methods:{
    openLink(url){
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>

</style>
